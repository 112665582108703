<template>
  <div id="app" class="app-container">
    <Header />
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
@import './assets/styles.css';
/* Global base styles and resets */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width calculations */
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  min-height: 100vh; /* Minimum height to fill the viewport height */
  width: 100%; /* Full width */
  padding: 0; /* Remove padding to match header */
  box-sizing: border-box; /* Includes padding in width calculation */
}

@media (max-width: 768px) {
  /* Responsive adjustments for smaller screens */
  #app {
    padding: 0px; /* Smaller padding on smaller screens */
  }
}
</style>
