<template>
  <footer class="app-footer">
    <div class="footer-container">
      <p>&copy; 2024 Espresso Consulting. All rights reserved.</p>
      <p>
        <a href="https://www.espressoconsult.com" target="_blank">Visit our website</a>
      </p>
      <p>Developed for Dev Ninja, Panama</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.app-footer {
  width: 100%;
  background-color: #151517;
  color: white;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px; /* Reduced padding for desktop view */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px; /* Default font size for desktop view */
}

.footer-container p {
  margin: 0; /* Removes default margins for cleaner alignment */
}

.footer-container a {
  color: white;
  text-decoration: none;
}

.footer-container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px; /* Even smaller padding for mobile view */
    font-size: 10px; /* Smaller font size for mobile view */
  }
  
  .footer-container p {
    margin: 2px 0; /* Add minimal vertical spacing between elements */
  }
}
</style>
