import { createRouter, createWebHistory } from 'vue-router';

const Index = () => import('@/views/Index.vue');
const AdminDashboard = () => import('@/views/AdminDashboard.vue');
const Register = () => import('@/components/Register.vue');
const ViewSquads = () => import('@/components/ViewSquads.vue');
const AdminRegistrations = () => import('@/components/AdminRegistrations.vue');
const AdminMatch = () => import('@/components/AdminMatch.vue');
const ProfileManagement = () => import('@/components/ProfileManagement.vue');
const MatchView = () => import('@/components/MatchView.vue');
const Success = () => import('@/components/Success.vue');  // Success component for Yappy
const Fail = () => import('@/components/Fail.vue');        // Fail component for Yappy
const TestYappy = () => import('@/views/TestYappy.vue');

const routes = [
  { path: '/', component: Index },
  { path: '/admin', component: AdminDashboard },
  { path: '/register/:id/:edit?', name: 'Register', component: Register },
  { path: '/edit-match/:id', name: 'edit-match', component: AdminMatch },
  { path: '/view-squads/:id', component: ViewSquads },
  { path: '/admin-registrations/:id', component: AdminRegistrations },
  { path: '/create-match', name: 'create-match', component: AdminMatch },
  { path: '/manage-profile', component: ProfileManagement },
  { path: '/match/:id', component: MatchView },
  { path: '/success/:id', name: 'Success', component: Success },  // Updated path to include registrationId
  { path: '/fail/:id', name: 'Fail', component: Fail },           // Updated path to include registrationId
  { path: '/testyappy', component: TestYappy },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
