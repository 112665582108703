import { createStore } from 'vuex';
import { getDoc, doc, getDocs, collection } from 'firebase/firestore';
import { db } from '@/firebaseConfig';

const store = createStore({
  state() {
    return {
      dropdownOptions: null,
      options: null,
      match: null,
      selectedCategories: [],
      selectedDivisions: [],
      selectedClasses: [],
    };
  },
  mutations: {
    setDropdownOptions(state, dropdownOptions) {
      state.dropdownOptions = dropdownOptions;
    },
    setOptions(state, options) {
      state.options = options;
    },
    setMatch(state, match) {
      state.match = match;
    },
    setSelectedCategories(state, categories) {
      state.selectedCategories = categories;
    },
    setSelectedDivisions(state, divisions) {
      state.selectedDivisions = divisions;
    },
    setSelectedClasses(state, classes) {
      state.selectedClasses = classes;
    },
    clearMatch(state) {
      state.match = null;
      state.selectedCategories = [];
      state.selectedDivisions = [];
      state.selectedClasses = [];
    }
  },
  actions: {
    async fetchDropdownOptions({ commit, state }) {
      if (state.dropdownOptions) return;

      try {
        const docRef = doc(db, 'dropdownOptions', 'dropdownOptions');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          commit('setDropdownOptions', docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    },
    async fetchOptions({ commit, state }) {
      if (state.options) return;

      try {
        const querySnapshot = await getDocs(collection(db, 'options'));
        const options = {};
        querySnapshot.forEach((doc) => {
          options[doc.id] = doc.data();
        });
        commit('setOptions', options);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    },
    async fetchMatch({ commit, dispatch }, matchId) {
      try {
        const matchDoc = await getDoc(doc(db, 'matches', matchId));
        if (matchDoc.exists()) {
          const matchData = { id: matchDoc.id, ...matchDoc.data() };
          commit('setMatch', matchData);
          dispatch('fetchSelectedOptions', matchData);
        } else {
          console.error('No such match!');
        }
      } catch (error) {
        console.error('Error fetching match:', error);
      }
    },
    fetchSelectedOptions({ commit }, match) {
      commit('setSelectedCategories', match.selectedCategories || []);
      commit('setSelectedDivisions', match.selectedDivisions || []);
      commit('setSelectedClasses', match.selectedClasses || []);
    },
    clearMatch({ commit }) {
      commit('clearMatch');
    }
  },
  getters: {
    dropdownOptions: (state) => state.dropdownOptions,
    options: (state) => state.options,
    match: (state) => state.match,
    selectedCategories: (state) => state.selectedCategories,
    selectedDivisions: (state) => state.selectedDivisions,
    selectedClasses: (state) => state.selectedClasses,
  },
});

export default store;
