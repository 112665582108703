<template>
  <header class="app-header">
    <div class="header-container">
      <router-link to="/">
        <img :src="require('../assets/logo.png')" alt="Site Logo" class="logo" />
      </router-link>
      <div class="nav">
        <router-link to="/" class="nav-link">Home</router-link>
        <router-link to="/" class="nav-link">About</router-link>
        <router-link to="/admin" class="nav-link">Admin</router-link>
      </div>
      <div class="burger-container">
        <button class="burger-menu" @click="toggleMenu" ref="burgerButton">
          ☰
        </button>
        <div class="menu" v-if="isMenuOpen" ref="menu">
          <ul>
            <li>
              <router-link to="/" class="nav-link" @click="closeMenu">Home</router-link>
            </li>
            <li>
              <router-link to="/admin" class="nav-link" @click="closeMenu">Admin</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
};
</script>

<style scoped>
.app-header {
  width: 100%;
  background-color: grey;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: space-between; /* Space between items */
  padding: 0 20px;
}

.logo {
  height: 125px; /* Keeping the original size */
}

.nav {
  display: flex;
  align-items: flex-end; /* Align nav links to the bottom */
  justify-content: right; /* Center nav links horizontally */
  flex-grow: 1; /* Allow nav to grow and center the content */
}

.burger-container {
  display: none;
  align-items: center;
  margin-left: auto; /* Push to the right */
  height: 100%; /* Make sure it takes full height for centering */
  position: relative; /* Add relative positioning to container */
}

.burger-menu {
  font-size: 36px; /* Increase size of burger icon */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  z-index: 1002; /* Ensure the icon is above the menu */
}

.menu {
  position: absolute; /* Position absolutely relative to burger container */
  top: 100%; /* Position just below the burger icon */
  right: 0;
  width: 100px; /* Adjust width as needed */
  background-color: orange;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight shadow for visibility */
  z-index: 1001; /* Ensure it's above other content but below the burger icon */
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  background-color: orange;
  margin: 0;
  padding: 0;
}

.nav-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: white;
  text-align: center;
}

.nav-link:hover {
  text-decoration: underline;
  color: red;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
  .burger-container {
    display: flex;
  }
  .app-header {
    width: 100%; /* Ensure header is 100% width on mobile */
  }
  .header-container {
    padding: 0 10px;
  }
  .logo {
    height: 80px; /* Reduce logo size for mobile view */
  }
  .burger-menu {
    font-size: 28px; /* Reduce burger icon size for mobile view */
  }
  .menu {
    top: 80%; /* Adjust the position of the menu */
  }
}

@media (min-width: 769px) {
  .burger-container {
    display: none;
  }
  .nav {
    display: flex;
  }
}
</style>
